import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/images/hero.png'; // Make sure to import your background image
import logo from '../assets/images/logo.png';

const ComingSoon = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement email submission logic
    console.log('Email submitted:', email);
    setEmail('');
  };

  return (
    <section className="bg-black text-white min-h-screen relative overflow-hidden">
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      
      {/* Header */}
      <header className="sticky top-0 z-50 bg-transparent pt-4">
        <nav className="max-w-[730px] mx-auto flex justify-between items-center px-6 py-3 rounded-[100px] bg-[#1a1a1a]">
          <div className="flex items-center">
            <img src={logo} alt="LeadTrek Logo" className="h-8 w-auto" />
          </div>
          <Link to="/" className="header-text bg-gradient-to-r from-[#C334F6] to-[#543DF4] text-white px-6 py-2 rounded-[100px] hover:opacity-90">
            Home
          </Link>
        </nav>
      </header>

      <div className="container mx-auto text-center relative z-10 pt-20 sm:pt-32 pb-20 sm:pb-40">
        <h1 className="font-['Neue_Montreal'] text-4xl sm:text-5xl md:text-[64px] font-medium leading-tight sm:leading-[78px] tracking-[0.002em] mb-8">
          LEADTREK IS <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#C334F6] to-[#543DF4]">COMING SOON</span>.
        </h1>
        <p className="font-['Neue_Montreal'] text-xl sm:text-2xl md:text-[28px] font-normal leading-normal sm:leading-[38px] tracking-[0.001em] mb-12 max-w-3xl mx-auto">
          BUT HEY, MAYBE YOUR REAL ESTATE AGENCY<br />
          ALREADY HAS ENOUGH QUALIFIED LEADS?🤔
        </p>
        <p className="font-['Neue_Montreal'] text-base sm:text-[18px] font-normal leading-normal sm:leading-[28px] tracking-[0.001em] mb-8 max-w-3xl mx-auto">
          If not, sign up for launch updates
        </p>
        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <input
            type="email"
            required
            className="w-full sm:w-96 px-6 py-3 border border-transparent text-base rounded-full text-gray-900 bg-white focus:outline-none focus:ring-2 focus:ring-purple-500"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className="bg-gradient-to-r from-[#C334F6] to-[#543DF4] text-white px-6 py-3 rounded-full text-base inline-flex items-center"
          >
            Subscribe
            <span className="ml-2 bg-white rounded-full p-1">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </span>
          </button>
        </form>
      </div>
    </section>
  );
};

export default ComingSoon;
